var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"text-center"},[_c('v-snackbar',{attrs:{"multi-line":"","width":"500px","centered":"","shaped":"","top":"","timeout":_vm.snackbarTimeout,"color":_vm.snackbarColor},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snackbarStatus = false}}},'v-btn',attrs,false),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-close")])],1)]}}]),model:{value:(_vm.snackbarStatus),callback:function ($$v) {_vm.snackbarStatus=$$v},expression:"snackbarStatus"}},[_c('p',{staticStyle:{"margin-bottom":"0!important"},domProps:{"innerHTML":_vm._s(_vm.snackbarText)}})])],1),_c('h3',[_vm._v("فریم ها")]),_c('v-divider',{staticClass:"mt-4 mb-4"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-5",attrs:{"align":"center","headers":_vm.headers,"items":_vm.frames.data,"items-per-page":10,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.keywords",fn:function(ref){
var item = ref.item;
return _vm._l((JSON.parse(item.keywords)),function(keyword,index){return _c('v-chip',{key:item.id + '-' + index,staticClass:"white--text ma-1",attrs:{"color":"#292C6D"}},[_vm._v(" "+_vm._s(keyword)+" ")])})}},{key:"item.frame",fn:function(ref){
var item = ref.item;
return [(item.frame)?_c('img',{staticClass:"mt-1",attrs:{"src":item.frame,"width":"100%"}}):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"x-small":"","fab":"","color":"#EC255A"},on:{"click":function($event){_vm.selectedItem = item; _vm.dialog = true}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-close")])],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-pagination',{attrs:{"color":"#161853","length":_vm.frames.last_page,"circle":""},on:{"input":_vm.getFrames},model:{value:(_vm.frames.current_page),callback:function ($$v) {_vm.$set(_vm.frames, "current_page", $$v)},expression:"frames.current_page"}})],1)],1),(_vm.selectedItem)?_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" آیا از حذف این فریم اطمینان دارید؟ ")]),_c('v-card-text'),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"#292C6D","text":""},on:{"click":_vm.deleteFrame}},[_vm._v(" بله ")]),_c('v-btn',{attrs:{"color":"#EC255A","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" خیر ")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }