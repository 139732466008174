<template>
  <div>
    <div class="text-center">
      <v-snackbar
          multi-line
          width="500px"
          centered
          shaped
          top
          v-model="snackbarStatus"
          :timeout="snackbarTimeout"
          :color="snackbarColor"
      >
        <p v-html="snackbarText" style="margin-bottom: 0!important;"></p>
        <template v-slot:action="{ attrs }">
          <v-btn
              text
              v-bind="attrs"
              @click="snackbarStatus = false"
          >
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </div>

    <h3>فریم ها</h3>
    <v-divider class="mt-4 mb-4"></v-divider>
    <v-row>
      <v-col cols="12">
        <v-data-table
            align="center"
            :headers="headers"
            :items="frames.data"
            :items-per-page="10"
            class="elevation-5"
            hide-default-footer
        >
          <template v-slot:item.keywords="{item}">
            <v-chip v-for="(keyword, index) in JSON.parse(item.keywords)"
                    :key="item.id + '-' + index"
                    color="#292C6D" class="white--text ma-1">
              {{ keyword }}
            </v-chip>
          </template>
          <template v-slot:item.frame="{item}">
            <img v-if="item.frame" class="mt-1" :src="item.frame" width="100%">
          </template>
          <template v-slot:item.actions="{item}">
            <v-btn x-small fab color="#EC255A" @click="selectedItem = item; dialog = true"><v-icon color="white">mdi-close</v-icon></v-btn>
          </template>
        </v-data-table>
      </v-col>

      <v-col cols="12">
        <v-pagination
            color="#161853"
            v-model="frames.current_page"
            :length="frames.last_page"
            circle
            @input="getFrames"
        ></v-pagination>
      </v-col>
    </v-row>

    <!--   Dialog   -->
    <v-dialog
        v-if="selectedItem"
        v-model="dialog"
        width="500"
    >
      <v-card>
        <v-card-title>
          آیا از حذف این فریم اطمینان دارید؟
        </v-card-title>

        <v-card-text>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="#292C6D"
              text
              @click="deleteFrame"
          >
            بله
          </v-btn>
          <v-btn
              color="#EC255A"
              text
              @click="dialog = false"
          >
            خیر
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: 'Frames',

  data() {
    return {
      frames: [],

      headers: [
        {text: 'شناسه', value: 'id', align: 'center'},
        {text: 'شناسه‌ی ویدیو', value: 'video_id', align: 'center'},
        {text: 'فریم', value: 'frame', align: 'center'},
        {text: 'کلیدواژه ها', value: 'keywords', align: 'center'},
        {text: 'عملیات', value: 'actions', align: 'center'},
      ],

      selectedItem: null,
      dialog: false,
    }
  },

  methods: {
    getFrames() {
      let page = this.frames.current_page ?? 1
      window.axios
          .get('/user/frames/index?page=' + page)
          .then((res) => {
            this.frames = res.data.data
          })
          .catch((err) => {
            console.log(err)
          })
    },

    deleteFrame() {
      window.axios
          .post('/user/frames/delete/' + this.selectedItem.id)
          .then(() => {
            this.openSnackbar('تصویر مورد نظر حذف شد', 'success')
            this.getFrames()
          })
          .catch((err) => {
            console.log(err)
          })
      this.dialog = false
      this.selectedItem = null
    }
  },

  mounted() {
    this.getFrames()
  }
}
</script>

<style scoped>

</style>